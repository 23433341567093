/* index.css */

/* Import the Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom CSS */

/* Variables */
:root {
  --bg-color: #fff; /* Light theme background color */
  --text-color: #333; /* Light theme text color */
  --nav-bg-color: #f0f0f0; /* Light theme navbar background */
  --footer-bg-color: #ddd; /* Light theme footer background */
}

[data-theme='dark'] {
  --bg-color: #010102; /* Dark theme background color */
  --text-color: #fff; /* Dark theme text color */
  --nav-bg-color: #1a1a1a; /* Dark theme navbar background */
  --footer-bg-color: #242424; /* Dark theme footer background */
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s, color 0.3s;
  overflow-x: hidden;

}

/* Custom classes for primary colors */
.bg-cream {
  background-color: #d5fadace;
}

.bg-yellow-500 {
  background-color: #39b54a;
}

.text-yellow-500 {
  color: #39B54A;
}

.text-darken {
  color: #263238;
}

/* Floating animations */
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 8px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.floating {
  animation: floating 3s ease-in-out infinite;
}

@keyframes floating-4 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 8px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.floating-4 {
  animation: floating-4 4s ease-in-out infinite;
}

/* Other base styles */
ul {
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
}

/* Hide scrollbar for WebKit browsers */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Custom styles for article content */
@layer components {
  .prose img,
  .prose video,
  .prose iframe {
    @apply w-full h-auto;
  }

  .prose iframe {
    aspect-ratio: 16 / 9;
  }

  /* Adjust margins and padding to prevent overflow */
  .prose {
    margin: 0 auto;
    padding: 0 1rem;
  }
}
